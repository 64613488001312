.home-page {
  height: 100vh;
  width: 100%;
  background-color: #d8e9f3;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.home-page-content {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.logo {
  width: 100%;
  max-width: 150px;
  margin-bottom: 20px;
}

.separator {
  height: 5px;
  background: #ff0000;
  width: 50px;
  margin: 20px auto;
}

.contact-info {
  margin-top: 20px;
}

.contact-icon {
  font-size: 1.2em;
  margin-right: 10px;
}

.contact-text {
  color: #002d72;
}

.main-title {
  color: #002d72;
}

.sub-title {
  color: #002d72;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-page-content {
    padding: 10px;
  }

  .logo {
    max-width: 100px;
  }

  .main-title {
    font-size: 1.5em;
  }

  .sub-title {
    font-size: 1.2em;
  }

  .contact-text {
    font-size: 1em;
  }
}
