.home2-page {
  background-color: #b2d3e6;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.header-section {
  margin-bottom: 20px;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}

.bmt-logo {
  width: 150px;
  height: auto;
}

.left-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  margin-top: 0;
}

.side-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
}

.contact-info {
  margin-top: 20px;
}

.subtitle {
  color: #000000;
}

.paragraph {
  color: black;
}

.contact-detail {
  color: #000000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .logo-container {
    justify-content: center;
    padding-left: 0;
  }

  .text-content {
    align-items: center;
    text-align: center;
  }

  .contact-info {
    align-items: center;
  }
}
